global.$ = global.jQuery = require('jquery');
require('jquery-ui');
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
require('./bootstrap');
// import owlCarousel from 'owl.carousel';
import 'select2/dist/js/select2.full.min';
// import 'bootstrap4-toggle/js/bootstrap4-toggle.min.js';

require('owl.carousel');
require('./sly');
require('./jquery.matchHeight');
require('./jquery.mCustomScrollbar');
require('./plugins');
require('./what-input');

global.Chart = require('chart.js/dist/Chart.min.js');


const Swal = window.Swal = require('sweetalert2');

window.Vue = require('vue');


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('get-products-week', require('./components/GetProductsWeek.vue').default);

Vue.component('get-products', require('./components/GetProducts.vue').default);
Vue.component('filter-component', require('./components/FilterComponent.vue').default);
Vue.component('filter-tag', require('./components/FilterTag.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app'
});
