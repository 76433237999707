<template>
    <div class="row">

        <div class="col-lg-4" v-for="(product, index) in products">
            <li class="product">
                <div class="product-outer">
                    <div class="product-inner">
                        <div class="product-image-wrapper">
                            <a :href="'/kategorija/'+slug+'/'+product.slug"
                               class="woocommerce-LoopProduct-link position-relative">
                                <div class="position-relative akcija_place">
                                    <img v-if="price == 'price1'  &&  (product.sale == 1 ||   product.sale == 3) "
                                         src="/img/akcija.png" class="position-absolute top-left akcija">
                                    <img v-if="price == 'price2'  &&  (product.sale == 2 ||   product.sale == 3) "
                                         src="/img/akcija.png" class="position-absolute top-left akcija">
                                    <img :src="product.image"
                                         class="img-responsive" alt="">
                                </div>
                            </a>
                        </div>
                        <div class="product-content-wrapper fotorama__no-select">
                            <a :href="'/kategorija/'+slug+'/'+product.slug"
                               class="woocommerce-LoopProduct-link">
                                <h3>{{product.name}}</h3>
                                <div itemprop="description">
                                    <p style="max-height: none;">
                                        <span v-for="(component, index) in product.components">
                                        {{component.name}},
                                        </span>
                                    </p>
                                </div>
                            </a>
                            <div class="yith_wapo_groups_container">
                                <div
                                    class="ywapo_group_container ywapo_group_container_radio form-row form-row-wide "
                                    data-requested="1" data-type="radio" data-id="1"
                                    data-condition="">
                                    <h3><span>{{lang=='en'?'Choose a size':'Izaberite veličinu'}}</span></h3>


                                    <div
                                        class="ywapo_input_container ywapo_input_container_radio noselect position-relative"
                                        v-for="(prices, index) in product.prices">
                                        <!--{{index}}-->

                                        <a v-if="price == 'price1' && prices.pivot.price1 != null" class="cpointer"
                                           @click="addtocart(prices.id,product.id)"
                                           data-toggle="tooltip" data-placement="left" title="Dodaj u korpu">
                                                <span class="ywapo_label_tag_position_after">
                                                    <span class="ywapo_option_label ywapo_label_position_after">{{prices.name}}</span>
                                                </span>
                                        </a>
                                        <a v-if="price == 'price1' && prices.pivot.price1 == null" class=""
                                           data-toggle="tooltip" data-placement="left" title="Dodaj u korpu">
                                                <span class="ywapo_label_tag_position_after">
                                                    <span class="ywapo_option_label ywapo_label_position_after">{{prices.name}}</span>
                                                </span>
                                        </a>


                                        <a v-if="price == 'price2' && prices.pivot.price2 != null" class="cpointer"
                                           @click="addtocart(prices.id,product.id)"
                                           data-toggle="tooltip" data-placement="left" title="Dodaj u korpu">
                                                <span class="ywapo_label_tag_position_after">
                                                    <span class="ywapo_option_label ywapo_label_position_after">{{prices.name}}</span>
                                                </span>
                                        </a>
                                        <a v-if="price == 'price2' && prices.pivot.price2 == null" class=""
                                           data-toggle="tooltip" data-placement="left" title="Dodaj u korpu">
                                                <span class="ywapo_label_tag_position_after">
                                                    <span class="ywapo_option_label ywapo_label_position_after">{{prices.name}}</span>
                                                </span>
                                        </a>
                                        <span
                                            class="ywapo_label_price position-relative">
                                            <span v-if="price == 'price1' && prices.pivot.price1 != null"
                                                  class="woocommerce-Price-amount amount">

                                            <span v-if="prices.pivot.dprice1">
                                                <span class="position-absolute top-left small oldprice"><del>{{formatPrice(prices.pivot.price1)}}</del></span>
                                                <span class="text-danger"> {{formatPrice(prices.pivot.dprice1)}}</span>
                                            </span>
                                                <span v-else>
                                                {{formatPrice(prices.pivot.price1)}}
                                            </span>

                                                <span class="woocommerce-Price-currencySymbol"> RSD</span>
                                            </span>




                                            <span v-if="price == 'price1' && prices.pivot.price1 == null"
                                                  class="woocommerce-Price-amount amount">
                                                <span class="woocommerce-Price-currencySymbol"><i data-toggle="tooltip"
                                                                                                  data-placement="left"
                                                                                                  title="Samo za pokupiti"
                                                                                                  class="po po-ready-delivery fs20 px-3"></i></span></span>

                                            <span v-if="price == 'price2' && prices.pivot.price2 != null"
                                                  class="woocommerce-Price-amount amount">
                                               <span v-if="prices.pivot.dprice2">
                                                <span class="position-absolute top-left small oldprice"><del>{{formatPrice(prices.pivot.price2)}}</del></span>
                                                <span class="text-danger"> {{formatPrice(prices.pivot.dprice2)}}</span>
                                            </span>
                                                <span v-else>
                                                {{formatPrice(prices.pivot.price2)}}
                                            </span>


                                                <span class="woocommerce-Price-currencySymbol"> RSD</span></span>

                                               <span v-if="price == 'price2' && prices.pivot.price2 == null"
                                                     class="woocommerce-Price-amount amount">
                                                <span class="woocommerce-Price-currencySymbol"><i data-toggle="tooltip"
                                                                                                  data-placement="left"
                                                                                                  title="Dostupno samo za dostavu"
                                                                                                  class="po po-scooter fs20 px-3"></i></span></span>

                                        </span>
                                    </div>


                                </div>
                            </div>
                            <div class="hover-area">
                                <a :href="'/kategorija/'+slug+'/'+product.slug"
                                   data-quantity="1"
                                   data-product_id="51" data-product_sku=""
                                   class="button product_type_simple add_to_cart_button ajax_add_to_cart">{{ lang == 'en' ? 'Customize / Extras':'Prilagodi / Dodaci'}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </div>


        <div v-if="yes" class="col-lg-4">
            <li class="product min-vh-75">

            </li>
        </div>
    </div>
</template>

<script type="application/javascript">

    export default {
        props: ['slug', 'rvisteklo', 'price'],
        data() {
            return {
                products: [],
                yes: null,
            }
        },

        methods: {
            addinfo(count, total) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 10000,
                    customClass: {
                        container: 'adddirect'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'success',
                    title: '<div>Dodato u korpu</div><div class="small"> ' + count + ' ' + total + '</div>',
                })

            },
            rvistekloMsg(text) {

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 10000,
                    customClass: {
                        container: 'adddirect'
                    },
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })

                Toast.fire({
                    icon: 'warning',
                    title: text,
                })

            },
            addtocart(size, product) {

                const data = {
                    size_id: size,
                    product_id: product,
                    slug: this.slug,
                };

                if (this.rvisteklo) {
                    this.rvistekloMsg('Radno vreme isteklo');
                } else {

                    axios.post('/addcartdirectalacart', data).then(({data}) => {
                        console.log(data);
                        console.log('AAAAAAAAAAAAAAAAAAAAAA');

                        if(data.err){
                            console.log('USAOOO');
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'center',
                                showConfirmButton: false,
                                timer: 10000,
                                customClass: {
                                    container: 'swalwaning'
                                },
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'warning',
                                title: '<div>Završite porudžbinu za dan koji ste započeli, zatim poručite za drugi dan. Ako želite da započnete drugi dan onda prvo ispraznite korpu..</div>',
                            })
                            return;
                        }

                        var count;
                        if (data.count == 1) {

                            count = data.count + ' proizvod';
                        } else {
                            count = data.count + ' proizvoda';
                        }
                        $('.count').html(count);
                        $('.countfixed').html(data.count);
                        $('.mobilecount').html(data.count);


                        var total = this.formatPrice(data.total) + ',<sup>00</sup>' + ' RSD';
                        $('.topamount').html(total);

                        console.log(data.total);

                        var prtotal = this.formatPrice(data.total) + ',<sup>00</sup>';
                        $('.pricefixed').html(prtotal);
                        $('.mobiletotalprice').html(prtotal);

                        if (data.rvCategoryIsteklo) {
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'center',
                                showConfirmButton: false,
                                timer: 10000,
                                customClass: {
                                    container: 'swalwaning'
                                },
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('mouseenter', Swal.stopTimer)
                                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                                }
                            })

                            Toast.fire({
                                icon: 'warning',
                                title: '<div>Radno vreme ove kategorije je isteklo.</div>',
                            })
                        } else {
                            this.addinfo(count, total);
                        }

                        // this.products = data.products;

                    })
                        // .catch((err) => {
                        //     this.addError(err);
                        // })
                        .catch((err) => console.error(err));
                }


            },
            read() {
                // console.log(slug);
                const data = {
                    slug: this.slug,
                    price: this.price,
                };
                axios.post('/vue/getproducts', data).then(({data}) => {
                    console.log(data);
                    this.products = data.products;
                    this.yes = data.yes;
                    Vue.nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                    })


                })
                    .catch((err) => console.error(err));

                $(function () {

                    $('[data-toggle="tooltip"]').tooltip();

                })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace(' ', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
        },
        mounted() {
            this.read();
            this.$root.$on('products', (products) => { // here you need to use the arrow function
                this.products = products;
            })
            this.$root.$on('yes', (yes) => { // here you need to use the arrow function
                this.yes = yes;
            })
        },
        created() {

        }
    }
</script>
