var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm._l(_vm.products, function(product, index) {
        return _c("div", { staticClass: "col-lg-4" }, [
          _c("li", { staticClass: "product" }, [
            _c("div", { staticClass: "product-outer" }, [
              _c("div", { staticClass: "product-inner" }, [
                _c("div", { staticClass: "product-image-wrapper" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "woocommerce-LoopProduct-link position-relative",
                      attrs: {
                        href: _vm.proslo
                          ? false
                          : "/" + _vm.slug + "/" + product.slug
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "position-relative akcija_place" },
                        [
                          _vm.price == "price1" &&
                          (product.sale == 1 || product.sale == 3)
                            ? _c("img", {
                                staticClass:
                                  "position-absolute top-left akcija",
                                attrs: { src: "/img/akcija.png" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.price == "price2" &&
                          (product.sale == 2 || product.sale == 3)
                            ? _c("img", {
                                staticClass:
                                  "position-absolute top-left akcija",
                                attrs: { src: "/img/akcija.png" }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "img-responsive",
                            attrs: { src: product.image, alt: "" }
                          })
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "product-content-wrapper fotorama__no-select"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "woocommerce-LoopProduct-link",
                        attrs: {
                          href: _vm.proslo
                            ? false
                            : "/" + _vm.slug + "/" + product.slug
                        }
                      },
                      [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.lang == "en" ? product.name1 : product.name
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { attrs: { itemprop: "description" } }, [
                          _c(
                            "p",
                            { staticStyle: { "max-height": "none" } },
                            _vm._l(product.components, function(
                              component,
                              index
                            ) {
                              return _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(component.name) +
                                    ",\n                                        "
                                )
                              ])
                            }),
                            0
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.proslo
                      ? _c(
                          "div",
                          { staticClass: "yith_wapo_groups_container" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ywapo_group_container ywapo_group_container_radio form-row form-row-wide ",
                                attrs: {
                                  "data-requested": "1",
                                  "data-type": "radio",
                                  "data-id": "1",
                                  "data-condition": ""
                                }
                              },
                              [
                                _c("h3", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.lang == "en"
                                          ? "Choose a size"
                                          : "Izaberite veličinu"
                                      )
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _vm._l(product.prices, function(prices, index) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ywapo_input_container ywapo_input_container_radio noselect position-relative"
                                    },
                                    [
                                      _vm.price == "price1" &&
                                      prices.pivot.price1 != null
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "cpointer",
                                              attrs: {
                                                "data-toggle": "tooltip",
                                                "data-placement": "left",
                                                title: "Dodaj u korpu"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.proslo
                                                    ? false
                                                    : _vm.addtocart(
                                                        prices.id,
                                                        product.id
                                                      )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ywapo_label_tag_position_after"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ywapo_option_label ywapo_label_position_after"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(prices.name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.price == "price1" &&
                                      prices.pivot.price1 == null
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                "data-toggle": "tooltip",
                                                "data-placement": "left",
                                                title: "Dodaj u korpu"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ywapo_label_tag_position_after"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ywapo_option_label ywapo_label_position_after"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(prices.name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.price == "price2" &&
                                      prices.pivot.price2 != null
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "cpointer",
                                              attrs: {
                                                "data-toggle": "tooltip",
                                                "data-placement": "left",
                                                title: "Dodaj u korpu"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.proslo
                                                    ? false
                                                    : _vm.addtocart(
                                                        prices.id,
                                                        product.id
                                                      )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ywapo_label_tag_position_after"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ywapo_option_label ywapo_label_position_after"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(prices.name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.price == "price2" &&
                                      prices.pivot.price2 == null
                                        ? _c(
                                            "a",
                                            {
                                              attrs: {
                                                "data-toggle": "tooltip",
                                                "data-placement": "left",
                                                title: "Dodaj u korpu"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "ywapo_label_tag_position_after"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "ywapo_option_label ywapo_label_position_after"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(prices.name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ywapo_label_price position-relative"
                                        },
                                        [
                                          _vm.price == "price1" &&
                                          prices.pivot.price1 != null
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount"
                                                },
                                                [
                                                  prices.pivot.dprice1
                                                    ? _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "position-absolute top-left small oldprice"
                                                          },
                                                          [
                                                            _c("del", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    prices.pivot
                                                                      .price1
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    prices.pivot
                                                                      .dprice1
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                prices.pivot
                                                                  .price1
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "woocommerce-Price-currencySymbol"
                                                    },
                                                    [_vm._v(" RSD")]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.price == "price1" &&
                                          prices.pivot.price1 == null
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount"
                                                },
                                                [_vm._m(0, true)]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.price == "price2" &&
                                          prices.pivot.price2 != null
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount"
                                                },
                                                [
                                                  prices.pivot.dprice2
                                                    ? _c("span", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "position-absolute top-left small oldprice"
                                                          },
                                                          [
                                                            _c("del", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    prices.pivot
                                                                      .price2
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.formatPrice(
                                                                    prices.pivot
                                                                      .dprice2
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("span", [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                prices.pivot
                                                                  .price2
                                                              )
                                                            ) +
                                                            "\n                                            "
                                                        )
                                                      ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "woocommerce-Price-currencySymbol"
                                                    },
                                                    [_vm._v(" RSD")]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.price == "price2" &&
                                          prices.pivot.price2 == null
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "woocommerce-Price-amount amount"
                                                },
                                                [_vm._m(1, true)]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.proslo
                      ? _c("div", { staticClass: "hover-area" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "button product_type_simple add_to_cart_button ajax_add_to_cart",
                              attrs: {
                                href: _vm.proslo
                                  ? false
                                  : "/" + _vm.slug + "/" + product.slug,
                                "data-quantity": "1",
                                "data-product_id": "51",
                                "data-product_sku": ""
                              }
                            },
                            [
                              _vm._v(
                                "    " +
                                  _vm._s(
                                    _vm.lang == "en"
                                      ? "Customize / Extras"
                                      : "Prilagodi / Dodaci"
                                  ) +
                                  "\n                                    "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ])
      }),
      _vm._v(" "),
      _vm.yes
        ? _c("div", { staticClass: "col-lg-4" }, [
            _c("li", { staticClass: "product min-vh-75" })
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "woocommerce-Price-currencySymbol" }, [
      _c("i", {
        staticClass: "po po-ready-delivery fs20 px-3",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "left",
          title: "Samo za pokupiti"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "woocommerce-Price-currencySymbol" }, [
      _c("i", {
        staticClass: "po po-scooter fs20 px-3",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "left",
          title: "Dostupno samo za dostavu"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }