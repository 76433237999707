var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "clear-food-type-filter chosen" }, [
      _c(
        "a",
        {
          staticClass: "cpointer",
          on: {
            click: function($event) {
              return _vm.getAll()
            }
          }
        },
        [_vm._v("Prikaži sve")]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "widget woocommerce widget_layered_nav" }, [
      _c(
        "ul",
        _vm._l(_vm.tags, function(tag) {
          return _c(
            "li",
            { key: tag.id, staticClass: "wc-layered-nav-term " },
            [
              _c(
                "a",
                {
                  staticClass: "cpointer",
                  on: {
                    click: function($event) {
                      return _vm.sendTag(tag.id)
                    }
                  }
                },
                [_vm._v(_vm._s(tag.name))]
              ),
              _vm._v(" "),
              _vm._m(0, true)
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "food-type-icon" }, [
      _c("i", { staticClass: "fa fa-cutlery" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }