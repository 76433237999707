<template>
    <div>
        <div class="clear-food-type-filter chosen"><a class="cpointer" @click="getAll()">Prikaži sve</a></div>
        <div class="widget woocommerce widget_layered_nav">
            <ul>
                <li class="wc-layered-nav-term " v-for="tag in tags" :key="tag.id">

                    <a class="cpointer" @click="sendTag(tag.id)">{{tag.name}}</a>
                    <span class="food-type-icon">
                    <i class="fa fa-cutlery"></i></span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script type="application/javascript">
    export default {
        props: ['slug'],
        data() {
            return {
                tags: [],
            }
        },
        methods: {
            getAll() {
                const data = {
                    slug: this.slug,
                };
                axios.post('/vue/getproducts', data).then(({data}) => {
                    // console.log(data);
                    // this.products = data.products;
                    this.$root.$emit('products', data.products);
                    $("input[type='checkbox'][name='selectedComponents[]']").prop("checked", false);
                })
                    .catch((err) => console.error(err));
            },
            read() {
                const data = {
                    tag: true,
                };
                axios.post('/vue/gettagcomponent', data).then(({data}) => {
                    this.tags = data.tags;

                })
                    .catch((err) => console.error(err));
            },
            sendTag(tag) {

                // console.log(comp);

                const data = {
                    tag_id: tag,
                    slug: this.slug,
                };
                axios.post('/vue/tagfilter', data).then(({data}) => {
                    // this.products = data.products;
                    this.$root.$emit('products', data.products);
                    Vue.nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                    })
                })
                    .catch((err) => console.error(err));


            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        },
        mounted() {
            this.read();

        }
    }
</script>
