<template>
    <div style="z-index: 9999;background: whitesmoke;" class="collapse products_filter_collapse"
         id="products_filter_collapse">
        <div class="row">
            <!--            <div class="col-lg-12">-->
            <!--                <div id="woocommerce_price_filter-2" class="widget woocommerce widget_price_filter">-->
            <!--                    <span class="gamma widget-title">Filter by price</span>-->
            <!--                    <div class="price_slider_wrapper">-->
            <!--                        <div-->
            <!--                            class="price_slider ui-slider ui-slider-horizontal ui-widget ui-widget-content ui-corner-all"-->
            <!--                            style="">-->
            <!--                            <div class="ui-slider-range ui-widget-header ui-corner-all"-->
            <!--                                 style="left: 0%; width: 100%;"></div>-->
            <!--                            <span class="ui-slider-handle ui-state-default ui-corner-all"-->
            <!--                                  tabindex="0" style="left: 0%;"></span><span-->
            <!--                            class="ui-slider-handle ui-state-default ui-corner-all" tabindex="0"-->
            <!--                            style="left: 100%;"></span>-->
            <!--                        </div>-->
            <!--                        <div class="price_slider_amount">-->
            <!--                            <div class="price_label" style="">Price: <span class="from">$1</span> —-->
            <!--                                <span class="to">$59</span></div>-->
            <!--                            <div class="clear"></div>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="col-lg-12">
                <div id="woocommerce_layered_nav-2" class="widget woocommerce widget_layered_nav">
                    <span class="gamma widget-title">Sastojci</span>

                    <div class="row">
                        <div class="col-lg-2" v-for="component in components" :key="component.id">
                            <label :id="'componentslabel'+component.id"
                                   :for="'components'+component.id"
                                   class="form-check  custom-control custom-checkbox additions position-relative w-100">
                                <input
                                    :value="component.id"
                                    name="selectedComponents[]"
                                    v-model="selectedComponents"
                                    @change="sendFilter(selectedComponents)"
                                    class="form-check-input custom-control-input"
                                    type="checkbox"
                                    :id="'components'+component.id"
                                >
                                <label class="form-check-label custom-control-label cpointer"
                                       :for="'components'+component.id">{{component.name}} </label>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script type="application/javascript">
    export default {
        props: ['slug'],
        data() {
            return {
                components: [],
                selectedComponents: [],
            }
        },
        methods: {
            read() {
                const data = {
                    slug: this.slug,
                };
                axios.post('/vue/gettagcomponent', data).then(({data}) => {
                    this.components = data.components;

                })
                    .catch((err) => console.error(err));
            },
            sendFilter(comp) {

                // console.log(comp);

                const data = {
                    components: this.selectedComponents,
                    slug: this.slug,
                };
                axios.post('/vue/productfilter', data).then(({data}) => {
                    // this.products = data.products;
                    this.$root.$emit('products', data.products);
                    this.$root.$emit('yes', data.yes);
                    Vue.nextTick(function () {
                        $('[data-toggle="tooltip"]').tooltip();
                    })
                })
                    .catch((err) => console.error(err));


            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
        },
        mounted() {
            this.read();

        }
    }
</script>
