var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "collapse products_filter_collapse",
      staticStyle: { "z-index": "9999", background: "whitesmoke" },
      attrs: { id: "products_filter_collapse" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            {
              staticClass: "widget woocommerce widget_layered_nav",
              attrs: { id: "woocommerce_layered_nav-2" }
            },
            [
              _c("span", { staticClass: "gamma widget-title" }, [
                _vm._v("Sastojci")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.components, function(component) {
                  return _c(
                    "div",
                    { key: component.id, staticClass: "col-lg-2" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "form-check  custom-control custom-checkbox additions position-relative w-100",
                          attrs: {
                            id: "componentslabel" + component.id,
                            for: "components" + component.id
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedComponents,
                                expression: "selectedComponents"
                              }
                            ],
                            staticClass:
                              "form-check-input custom-control-input",
                            attrs: {
                              name: "selectedComponents[]",
                              type: "checkbox",
                              id: "components" + component.id
                            },
                            domProps: {
                              value: component.id,
                              checked: Array.isArray(_vm.selectedComponents)
                                ? _vm._i(_vm.selectedComponents, component.id) >
                                  -1
                                : _vm.selectedComponents
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.selectedComponents,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = component.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectedComponents = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectedComponents = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectedComponents = $$c
                                  }
                                },
                                function($event) {
                                  return _vm.sendFilter(_vm.selectedComponents)
                                }
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass:
                                "form-check-label custom-control-label cpointer",
                              attrs: { for: "components" + component.id }
                            },
                            [_vm._v(_vm._s(component.name) + " ")]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }